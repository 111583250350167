import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getModelById, updateModelById } from '../services/apiService'
import DeviceForm from '../parts/DeviceForm'
import UserForm from '../parts/UserForm'
import ShiftForm from '../parts/ShiftForm'
import TableForm from '../parts/TableForm'
import BookingForm from '../parts/BookingForm'
import OriginForm from '../parts/OriginForm'
import EventForm from '../parts/EventForm'
import LogForm from '../parts/LogForm'
import useApp from '../utils/useApp'
import PaymentForm from '../parts/PaymentForm'
import ProductForm from '../parts/ProductForm'
import VenueForm from '../parts/VenueForm'
import BookingLinesDatatable from '../parts/BookingLinesDatatable'
import BookingPayments from '../parts/BookingPayments'
import BookingLinesForm from '../parts/BookingLinesForm'
import BookingLogs from '../parts/BookingLogs'
import BookingPaymentInfo from '../parts/BookingPaymentInfo'
import translateModels from '../utils/translate-models'
import ModifierForm from '../parts/ModifierForm'
import PriceRuleForm from '../parts/PriceRuleForm'

const pagesMap = {
	booking: 'sales',
	bookingLine: 'bookings-lines',
	event: 'events',
	payment: 'payments',
	product: 'products',
	users: 'user',
	shift: 'shifts',
	table: 'tables',
	device: 'devices',
	origin: 'origins',
	venue: 'venues',
	log: 'logs',
	priceRule: 'price-rules'
}

const EditPage = () => {
	const { model, id } = useParams()
	const [data, setData] = useState({})
	const {
		state: { user },
	} = useApp()
	const [bookingPaymentInfo, setBookingPaymentInfo] = useState({})
	const [responseMessage, setResponseMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	const getItemToEdit = async (model, id) => {
		const result = await getModelById(user.lastVenue, model, id)
		//console.log('result', result)
		if (result.result === 'ok') {
			setData(result.data)
			if (model === 'booking') {
				const bookingPaymentInfo = {
					price: result.data.price,
					paymentStatus: result.data.paymentStatus,
				}
				setBookingPaymentInfo(bookingPaymentInfo)
			}
		}
	}

	const updateModel = async (data, duplicate = false) => {
		const result = await updateModelById(
			['origin', 'event', 'priceRule'].includes(model) ? data.venueId : user.lastVenue, 
			model, 
			duplicate ? 0 : id, 
			data
		)
		// console.log('result', result)
		if (result.action === 'updated') {
			setResponseMessage('Registro actualizado correctamente')
		} else if (result.action === 'created') {
			setResponseMessage('Resgistro creado correctamente')
			window.location.href = result.result.id
		} else {
			setResponseMessage('Error en la petición')
		}
	}

	useEffect(() => {
		getItemToEdit(model, id)
		// eslint-disable-next-line
	}, [])

	return (
		<div className="card">
			<div className="card-body edit_page">
				<div className='header'>
					<p className="page_title">
						{id==='0' ? 'CREAR: ' : 'EDITAR: '} <span className="model-name">{translateModels[model]}</span>
					</p>
					<button className='icon_transparent_button page_title' onClick={() => {
						window.location.pathname = '/' + pagesMap[model]
					}}>
						<span className='ti ti-arrow-left'></span>Volver
					</button>
				</div>

				{model === 'device' && <DeviceForm data={data} sendToUpdate={updateModel} />}
				{model === 'user' && (
					<UserForm
						data={data}
						sendToUpdate={updateModel}
						responseMessage={responseMessage}
						errorMessage={errorMessage}
						setErrorMessage={setErrorMessage}
					/>
				)}
				{model === 'shift' && (
					<ShiftForm data={data} sendToUpdate={updateModel} responseMessage={responseMessage} />
				)}
				{model === 'table' && (
					<TableForm data={data} sendToUpdate={updateModel} responseMessage={responseMessage} />
				)}
				{model === 'booking' && (
					<div className="d-flex justify-content-between">
						<div className="col-6">
							<BookingForm data={data} sendToUpdate={updateModel} />
						</div>
						<div className="col-6">
							<BookingLinesDatatable booking={data} />
							<BookingPayments bookingId={data.id} />
							<BookingPaymentInfo bookingPaymentInfo={bookingPaymentInfo} />
							<BookingLogs booking={data} />
						</div>
					</div>
				)}
				{model === 'bookingLine' && <BookingLinesForm data={data} sendToUpdate={updateModel} />}
				{model === 'origin' && (
					<OriginForm data={data} sendToUpdate={updateModel} responseMessage={responseMessage} />
				)}
				{model === 'event' && (
					<EventForm data={data} sendToUpdate={updateModel} responseMessage={responseMessage} />
				)}
				{model === 'log' && <LogForm data={data} />}
				{model === 'payment' && <PaymentForm data={data} sendToUpdate={updateModel} />}
				{model === 'product' && (
					<ProductForm data={data} sendToUpdate={updateModel} responseMessage={responseMessage} />
				)}
				{model === 'modifier' && (
					<ModifierForm data={data} sendToUpdate={updateModel} responseMessage={responseMessage} />
				)}
				{model === 'venue' && <VenueForm data={data} sendToUpdate={updateModel} />}
				{model === 'priceRule' &&
					<PriceRuleForm data={data} sendToUpdate={updateModel} responseMessage={responseMessage}/>
				}
			</div>
		</div>
	)
}

export default EditPage
