import React, { useState } from 'react'

const Switch = ({
    onInput = (e)=>{},
    name = '',
    selectId = '',
    disable = false,
    label = '',
    labelPos = '',
    defaultValue = 0
}) => {
    
    const handleInputEvent = () => {

        onInput({
            target : {
                name: name || 'input-select',
                value: Number(!!!defaultValue)
            }
        })

    }

    return (
        <div className={`switch-container ${disable ? 'disable' : ''}`} id={selectId || 'input-select'} style={{flexDirection: `${labelPos.length>0 ? labelPos : 'row'}`}}>
            {label.length>0 && (<p className='label'>{label}</p>)}
            <div className={`switch ${!!defaultValue ? 'on' : 'off'}`} onClick={()=>{
                handleInputEvent() 
            }} >
                <span></span>
            </div>
        </div>
    )
}
export default Switch
