import { useState, useEffect } from "react"
import { getDataApi, updateModelById } from "../services/apiService"
import { getMonday } from "../utils/tracking-data";
import Switch from "./Switch";
import Filters from "./filters/filters";
import { shifts } from "../utils/bookings-data";

let defaultFilters = {
    venueId: 0,
    shiftId: []
}

let hasrendered = false
let days = ['lun','mar','mié','jue','vie','sáb','dom']
let days_UTC = ['dom', 'lun','mar','mié','jue','vie','sáb']

const EventsPerWeek = () => {

    const [data, setData] = useState([])
    const [currentMonday, setCurrentMonday] = useState(getMonday())
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState(defaultFilters)

    const getEvents = async (date = Date.prototype) => {
        setLoading(true)
        const _ = new Date(date)
        const eventDate = [_.toISOString().substring(0, 10)+'T00:00', new Date(_.setDate(_.getDate() + 6)).toISOString().substring(0, 10)+'T23:59']
        const result = await getDataApi(0, 'event', 0, 1000, null, null, 'date', 'ASC', null, {...filters, date: eventDate})
        if (!result.data) {
            setLoading(false)
            return
        }
        let events = {}
        result.data.forEach((event) => {
            let [date, time] = event.date.split('T')
            let [year, month, day] = date.split('-').map((s) => Number(s))
            let [hours, minutes] = time.split(':').map((s) => Number(s))
            let date_UTC = new Date(Date.UTC(year, month-1, day, hours, minutes, 0, 0))
            let day_UTC = days_UTC[date_UTC.getUTCDay()]
            events[day_UTC] = events[day_UTC] || []
            events[day_UTC].push(event)
        })
        setData(events)
        setLoading(false)
    }

    const goForWard = () => setCurrentMonday(new Date(currentMonday.setDate(currentMonday.getDate()+7)))
    const goBackWard = () => setCurrentMonday(new Date(currentMonday.setDate(currentMonday.getDate()-7)))

    const handleEventSwitches = async (e, event) => {
        //event is venue event, not DOM event
        setLoading(true)
        let {name, value} = e.target
        const result = await updateModelById(event.venueId, 'event', event.id, {[name]: value})
        getEvents(currentMonday)
        setLoading(false)
    }

    useEffect(() => {

        getEvents(currentMonday)

    }, [currentMonday])

    useEffect(() => {
        if (!hasrendered) {
            hasrendered = true
            return
        }
        getEvents(currentMonday)
    }, [filters])

    return (
        <div className="events_per_week_container">
            
            <div className="controls">
            <Filters 
                config={{
                    page: 'eventsPage',
                    filtersInterface: defaultFilters,
                    rows: [
                        [
                            {
                                id: 'slct-tablao',
                                key: 'venueId',
                                type: 'select',
                                role: 'filter',
                                label: 'Tablao',
                                preferences: ['onlyOne', 'autoHide', 'showCurrentValue', 'toggleSelects'],
                                optionsAndTags: () => [[
                                    { value: 0, name: 'Todos' },
                                    { value: 1, name: 'Cardamomo' },
                                    { value: 2, name: '1911' },
                                ], []],
                            },
                            {
                                id: 'slct-shift',
                                key: 'shiftId',
                                type: 'select',
                                role: 'filter',
                                label: 'Pase',
                                preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
                                optionsAndTags: async () => [shifts, []]
                            }
                        ],
                    ],
                }}
                onFilterChange={(e) => setFilters(e)}
            />
                <div>
                    <p className="info_week">{`Del ${currentMonday.toLocaleDateString('es-ES', {weekday: 'short', day: '2-digit', month: 'short'})} al ${new Date(new Date(currentMonday).setDate(currentMonday.getDate() + 6)).toLocaleDateString('es-ES', {weekday: 'short', day: '2-digit', month: 'short', year: 'numeric'})}`.split(',').join('')}</p>
                    <div>
                        <button className={`${loading ? 'disable' : ''} ti ti-arrow-left icon_transparent_button`} onClick={goBackWard}></button>
                        <button className={`${loading ? 'disable' : ''} ti ti-arrow-right icon_transparent_button`} onClick={goForWard}></button>
                    </div>
                </div>
            </div>
            <div className="week_content">
                <div className="day_headers">
                {
                    days.map((day, i) => <div className="day" key={i}>{day} - {new Date(currentMonday.getFullYear(), currentMonday.getMonth(), currentMonday.getDate() + i).getDate()}</div>)
                }
                </div>
                <div className="events_container">
                { 
                    days.map((day, i) => {
                        return (
                        <div className="day_column" key={i}>
                            {   data[day] ?
                                data[day].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).map((event, i) => {
                                    return event.venueId===1 || event.venueId===2 ? (
                                        <div className="event_cell" key={i}>
                                            <div onClick={()=>{window.location.href = `/event/edit/${event.id}`}}>
                                                <p>{new Date(event.date).toISOString().substring(11, 16)}<br />{event.venueId===1 ? 'Cardamomo' : '1911'}</p>
                                                <img src={
                                                    event.venueId === 1 ? 'https://rumba.cardamomo.com/img/icon-venue-1.png' :
                                                    'https://rumba.cardamomo.com/img/users/9f26afbd-c3d7-4149-af0a-f5081c876469.png'
                                                }/>
                                            </div>
                                            <Switch
                                                name='rumbaOpen'
                                                label="Rumba"
                                                defaultValue={event.rumbaOpen}
                                                onInput={(e) => handleEventSwitches(e, event)}
                                                disable={loading}
                                            />
                                            <Switch
                                                name='webOpen'
                                                label="Web"
                                                defaultValue={event.webOpen}
                                                onInput={(e) => handleEventSwitches(e, event)}
                                                disable={loading}
                                            />
                                            <Switch
                                                name='phoneRequired'
                                                label="Teléfono"
                                                defaultValue={event.phoneRequired}
                                                onInput={(e) => handleEventSwitches(e, event)}
                                                disable={loading}
                                            />
                                            
                                        </div>
                                    ) : null
                                })
                                : 
                                <div className="event_cell no_data" >
                                    <p>No hay eventos para este día</p>
                                </div>
                            }
                        </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default EventsPerWeek